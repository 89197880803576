(function () {
    'use strict';

    /**
     * A widget to enter and manage a list of email addresses.
     *
     * @method alphaEmailInput
     *
     * @example
     *      HTML:
     *      <input alpha-email-input
     *             id="some_id"
     *             ng-model="SomeCtrl.someModel"
     *             event-context="some_context"
     *             event-record-type="some_record_type"
     *             event-field-type="some_field_type"
     *             read-only-mode="true">
     *
     * @param {String} id Unique ID of the element
     * @param {String} ng-model Model to bind this component to
     * @param {String} [event-context] Context for the field to listen to (rules, formulas, etc.)
     * @param {String} [event-record-type] Record type for the field to listen to (rules, formulas, etc.)
     * @param {String} [event-field-type] Field type for the field to listen to (rules, formulas, etc.)
     * @param {Boolean} [read-only-mode] Makes the input permanently read only even if a rule makes it writable.
     */

    angular
        .module('alpha.common.directives.alphaEmailInput', [
            'ui.bootstrap',
            'alpha.common.alphaSmartTable',
            'alpha.directives.ventivSelectValue',
            'alpha.utils.Loader',
            'alpha.utils.I18n',
            'alpha.utils.RequiredFieldUtils',
            'alpha.common.services.ruleExecutionHelper',
            'AlphaApi',
            'UserPreferences'
        ])
        .directive('alphaEmailInput', alphaEmailInput);

    alphaEmailInput.$inject = [
        '$q',
        'UserPreferences',
        'SecurityInterface',
        'LoaderService',
        'I18nUtil',
        'RequiredFieldService',
        'RuleExecutionHelper',
        'RecordDataUtil',
        'EMAIL_REGEX'
    ];

    function alphaEmailInput(
        $q,
        UserPreferences,
        SecurityInterface,
        LoaderService,
        I18nUtil,
        RequiredFieldService,
        RuleExecutionHelper,
        RecordDataUtil,
        EMAIL_REGEX
    ) {
        return {
            restrict: 'A',
            require: 'ngModel',
            replace: true,
            link: link,
            template: template,
            scope: {
                containerId : '@id',
                selectedEmailCallback : '=',
                customEmailRegEx : '@customEmailRegEx'
            }
        };

        function link(scope, element, attrs, ngModelCtrl){
            var _readOnlyMode, _readOnlyState, _currentTableState, _previousTableState, _previousTotalRecords = 0, _clientId;

            scope.userList = [];
            scope.addresses = [];
            scope.selectorOpen = false;
            scope.selectorOptions = [
                I18nUtil.getI18nString('LBL_INPUT', 'Input'),
                I18nUtil.getI18nString('LBL_SYSTEM_USERS', 'System Users')
            ];
            scope.emailRegex = new RegExp(scope.customEmailRegEx || EMAIL_REGEX);
            scope.addEmail = addEmail;
            scope.cancel = cancel;
            scope.removeEmail = removeEmail;
            scope.showEmailSelector = showEmailSelector;
            scope.pipeUsers = _pipeUsers;
            scope.downloadUsers = _downloadUsers;
            scope.showEmailButton = true;
            scope.canShowEmailButton = canShowEmailButton;
            scope.columns =[{
                id: 'user_name',
                name: 'userName',
                displayName: I18nUtil.getI18nString('LBL_USER_NAME', 'User Name'),
                visible: true,
                isDefaultSort: true,
                width: '35%'
            },{
                id: 'email_addresses',
                name: 'email',
                displayName: I18nUtil.getI18nString('LBL_EMAIL_ADDRESSES', 'Email Addresses'),
                visible: true,
                isDefaultSort: false,
                width: '35%'
            },{
                id: 'first_name',
                name: 'firstName',
                displayName: I18nUtil.getI18nString('LBL_FIRST_NAME', 'First Name'),
                visible: true,
                isDefaultSort: false,
                width: '15%'
            },{
                id: 'last_name',
                name: 'lastName',
                displayName: I18nUtil.getI18nString('LBL_LAST_NAME', 'Last Name'),
                visible: true,
                isDefaultSort: false,
                width: '15%'
            }];
            ngModelCtrl.$formatters.unshift(_formatter);
            ngModelCtrl.$parsers.unshift(_parser);
            ngModelCtrl.$render = _render;

            RuleExecutionHelper.subscribeToFieldEvents(scope, ['setRequired'], attrs, _eventHandler);
            attrs.$observe('readOnlyMode', function(newVal) {
                if (newVal === 'true') {
                    _readOnlyMode = true;
                    _enableReadOnly();
                } else {
                    _readOnlyMode = false;
                    if (!_readOnlyState) {
                        _disableReadOnly();
                    }
                }
            });

            attrs.$observe('applyRedaction', function(newVal){
                if(newVal === 'true' && ngModelCtrl){
                    scope.showEmailButton = false;
                    var viewValue = ngModelCtrl.$modelValue;
                    var formatters = ngModelCtrl.$formatters;
                    for (var i = formatters.length - 1; i >= 0; --i) {
                        viewValue = formatters[i](viewValue);
                    }
                    ngModelCtrl.$viewValue = viewValue;
                    ngModelCtrl.$render();
                }
            });

            //Private Functions

            function _eventHandler(data, event) {
                switch (event.topic) {
                    case 'setReadOnly':
                        _setReadOnly();
                        break;
                    case 'setRequired':
                        _setRequired();
                        break;
                }
                function _setReadOnly() {
                    if (data.value === true) {
                        _readOnlyState = true;
                        _enableReadOnly();
                    } else if (data.value === false) {
                        _readOnlyState = false;
                        if (!_readOnlyMode) {
                            _disableReadOnly();
                        }
                    }
                }
                function _setRequired() {
                    if (data.value === true) {
                        _enableRequired();
                    } else if (data.value === false) {
                        _disableRequired();
                    }
                }
            }
            function _enableReadOnly() {
                scope.isReadOnly = true;
            }
            function _disableReadOnly() {
                scope.isReadOnly = false;
            }
            function _enableRequired() {
                ngModelCtrl.$validators.required = RequiredFieldService.isRequiredFieldValid;
                ngModelCtrl.$setValidity('required', RequiredFieldService.isRequiredFieldValid(ngModelCtrl.$modelValue));
            }
            function _disableRequired() {
                ngModelCtrl.$setValidity('required', true);
                delete ngModelCtrl.$validators.required;
            }
            function _formatter(modelValue){
                var viewValue;
                if(_.isString(modelValue) && !_.isEmpty(modelValue)){
                    var tempValue;
                    var emails = modelValue.split(',');
                    if(attrs.applyRedaction === 'true'){
                         tempValue = emails.map(function(email) {
                            return email ? '******' : email;
                        });
                        tempValue = tempValue.join(',');
                    } else {
                        tempValue = modelValue;
                    }
                    viewValue = tempValue.split(/[;,]+/);
                }
                return viewValue;
            }
            function _parser(viewValue){
                var modelValue;
                if(_.isArray(viewValue)){
                    modelValue = viewValue.join();
                }
                return modelValue;
            }
            function _render(){
                scope.addresses = ngModelCtrl.$viewValue;
            }

            function _formatFilter(predicateObject) {

                var searchObject = angular.copy(predicateObject);

                if (searchObject.$) {
                    return {
                        userName: searchObject.$,
                        firstName: searchObject.$,
                        lastName: searchObject.$,
                        email: searchObject.$
                    };
                }

                return null;
            }

            function _getSearchRequest(skip, limit, sortColumn, sortDirection, filter) {
                var orderBy = [];
                var filterBy = [];

                if (sortColumn) {
                    orderBy.push({field: sortColumn, order: sortDirection});
                }

                if (!_.isEmpty(filter)) {

                    _.forEach(filter, function(value, key) {
                        filterBy.push({field: {fieldName: key}, operation: 'LIKE', value: value});
                    });
                }

                return {
                    skip: skip,
                    limit: limit,
                    orderBy: orderBy,
                    terms: filterBy
                };
            }

            function _downloadSuccess(data, tableState, deferred) {
                deferred.resolve(data.userPage.page.content);
            }

            function _gridSuccess(data, tableState) {
                scope.singleAddress = '';
                scope.userList = data.userPage.page.content;
                tableState.pagination.numberOfPages = data.userPage.page.totalPages;
                _previousTotalRecords = data.userPage.page.totalElements;
            }

            function _downloadUsers() {

                var deferred = $q.defer();

                // Make a copy so that we do not mess up the search on the grid
                var tableState = angular.copy(_currentTableState);
                tableState.pagination.start = 0;
                tableState.pagination.number = 5000;

                _fetchUsers(tableState, _downloadSuccess, deferred, true);

                return deferred.promise;
            }

            function canShowEmailButton(){
                return scope.showEmailButton;
            }

            function _fetchUsers(tableState, success, deferred, downLoadUsers) {

                LoaderService.startLoading();

                if (!tableState.search.predicateObject) {
                    tableState.search.predicateObject = {};
                }

                SecurityInterface.getAllUsersLightForClient(
                    _clientId, _getSearchRequest(tableState.pagination.start,
                        tableState.pagination.number, tableState.sort.predicate, tableState.sort.reverse ? "DESC" : "ASC",
                        _formatFilter(tableState.search.predicateObject)),
                    _previousTotalRecords, 0,0,0, downLoadUsers,
                    function getUserSuccess(data) {
                        if (success && deferred) {
                            success(data, tableState, deferred);
                        } else if (success) {
                            success(data, tableState);
                        }
                        LoaderService.stopLoading();
                    }, function getUserError(reason) {
                        LoaderService.stopLoading();
                    });
            }

            function _pipeUsers(tableState) {

                _currentTableState = tableState;

                if (!_.isEmpty(_previousTableState)) {
                    var reset = false;

                    if (_previousTableState.search.predicateObject && tableState.search.predicateObject) {
                        if (_.isEmpty(_previousTableState.search.predicateObject) && !_.isEmpty(tableState.search.predicateObject)) {
                            reset = true;
                        } else if (!_.isEmpty(_previousTableState.search.predicateObject) && _.isEmpty(tableState.search.predicateObject)) {
                            reset = true;
                        } else if (_previousTableState.search.predicateObject.$ !== tableState.search.predicateObject.$) {
                            reset = true;
                        }
                    }
                    else if ((_previousTableState.search.predicateObject && !tableState.search.predicateObject) ||
                        (!_previousTableState.search.predicateObject && tableState.search.predicateObject)) {
                        reset = true;
                    }

                    if (reset) {
                        _previousTotalRecords = 0;
                    }
                }

                _fetchUsers(tableState, _gridSuccess, undefined, false);
                _previousTableState = JSON.parse(JSON.stringify(tableState));
            }

            //Public Functions
            function cancel(){
                scope.selectorOpen = false;
            }
            function addEmail(){
                var addresses = [],userDetails={};
                if(scope.emailInputType === scope.selectorOptions[0] && !_.isEmpty(scope.singleAddress)){
                    addresses = scope.singleAddress.split(/[;,]+/);
                    userDetails['addresses'] = addresses;
                }else{
                    addresses = _.filter(scope.userList, 'isSelected');
                    userDetails['addresses'] = addresses;
                    addresses = _.map(addresses, 'email');
                }
                userDetails['source'] = scope.emailInputType;

                addresses = _.union(ngModelCtrl.$viewValue, addresses,scope.addresses);
                ngModelCtrl.$setViewValue(addresses);
                ngModelCtrl.$render();
                scope.selectorOpen = false;
                if(_.isFunction(scope.selectedEmailCallback)){
                    scope.selectedEmailCallback(userDetails);
                }
            }
            function removeEmail(address){
                var addresses = _.without(ngModelCtrl.$viewValue, address);
                ngModelCtrl.$setViewValue(addresses);
                ngModelCtrl.$render();
            }
            function showEmailSelector(){
                if(!scope.selectorOpen){
                    LoaderService.startLoading();
                    UserPreferences.getClientId()
                        .then(function success(clientId) {
                            _clientId = clientId;
                            scope.singleAddress = '';
                            scope.emailInputType = scope.selectorOptions[0];
                        })
                        .finally(function() {
                            LoaderService.stopLoading();
                        });
                }
            }
        }

        function template(element, attrs){
          return    '<div class="input-group alpha-email-input-container">'+
                    '   <ul class="alpha-email-addresses"' +
                    '       ng-class="{\'read-only\': isReadOnly}">' +
                    '       <li ng-repeat="address in addresses track by $index" id="'+ attrs.id +'__email__address_{{$index}}_{{address}}"  class="alpha-email-address badge">' +
                    '           <button id="'+ attrs.id +'__email__address_{{$index}}_{{address}}_btn" class="close" ng-click="removeEmail(address)" uib-tooltip="{{\'LBL_CLOSE\' | translate}}" tooltip-append-to-body="true" ng-disabled="isReadOnly">&times;</button>' +
                    '           <span id="'+ attrs.id +'__email__address_{{$index}}_{{address}}_span" ng-bind="address"></span>' +
                    '           ' +
                    '       </li>' +
                    '   </ul>' +
                    '   <div class="input-group-btn">' +
                    '       <button id="'+ attrs.id +'__email_btn" ' +
                    '               class="alpha-email-button btn btn-default btn-sm"' +
                    '               ng-click="showEmailSelector()"' +
                    '               uib-tooltip="{{\'LBL_ADD\' | translate}}" ' +
                    '               tooltip-append-to-body="true"'+
                    '               uib-popover-template="\'' + applicationContextRoot + '/static/custom/common/app/partials/popovers/alphaEmailAddressSelector.html\'"' +
                    '               popover-class="alpha-popover alpha-email-popover" popover-is-open="selectorOpen" popover-placement="bottom" ' +
                    '               ng-disabled="isReadOnly"><span class="glyphicon glyphicon-plus"></span>' +
                    '       </button>' +
                    '   </div>'+
                    '</div>';
        }
    }
})();
