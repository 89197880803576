(function() {
    'use strict';
    angular
        .module('alpha.login.Login')
        .constant('USER_ID','userId')
        .controller('LoginModalController', LoginModalController);

    LoginModalController.$inject = [
        'LoaderService',
        'LoginService',
        '$uibModalInstance',
        'I18nUtil',
        '$timeout',
        '$cookies',
        '$window',
        '$http',
        '$q',
        'USER_ID',
        'LOGIN_PAGE_PATH',
        'AlphaLocalStorageService'
    ];

    function LoginModalController(
        LoaderService,
        LoginService,
        $uibModalInstance,
        I18nUtil,
        $timeout,
        $cookies,
        $window,
        $http,
        $q,
        USER_ID,
        LOGIN_PAGE_PATH,
        AlphaLocalStorageService
    ) {
        var vm = this;

        vm.doLogin = doLogin;
        vm.closeModal = closeModal;
        vm.validateTotp = validateTotp;
        vm.showLoginForm = showLoginForm;
        vm.showTotpForm = showTotpForm;
        vm.forgotPassword = forgotPassword;
        vm.showForgotPassword = showForgotPassword;
        vm.renewSSOSession = renewSSOSession;
        vm.sendCodeViaEmail = sendCodeViaEmail;
        vm.mfaViaEmail=false;
        vm.customLoginPageInfo = {};
        vm.endSession= endSession;

        // Initialization

        vm.login = {
            name: AlphaLocalStorageService.getDataFromStorage(USER_ID),
            password: null,
            rememberMe: LoginService.getDefaultRememberMe()
        };
        _loadLanguageOptions();
        vm.showLoginForm();

        // Public methods

        function closeModal() {
            $uibModalInstance.close();
        }

        function dismissModal(){
            $uibModalInstance.dismiss();
        }

        function doLogin() {
            LoaderService.startLoading();
            LoginService.renewCsrfToken()
                .then(function() {
                    return LoginService.logIn(vm.login.name, vm.login.password, vm.login.rememberMe, vm.login.preferredLanguage,'Y');
                })
                .then(function(data) {
                    if ($uibModalInstance && LoginService.setDefaultName(vm.login.name)) {
                        if (data.IS_MFA) {
                            vm.clientMFAsetting = AlphaLocalStorageService.getDataFromStorage('clientMFASetting');
                            vm.TOTPMaxAge = AlphaLocalStorageService.getDataFromStorage('TOTPMaxAge');
                            vm.sendEmail = I18nUtil.getI18nString('BTN_SEND_EMAIL', 'Send Email');
                            vm.TOTPMaxAgeMessage = I18nUtil.getI18nString('LBL_TRUST_COOKIES_MESSAGE', 'Trust this device for {{TOTPMaxAge}} days', {TOTPMaxAge: vm.TOTPMaxAge || 30});
                            if(vm.clientMFAsetting=='Email'){
                                vm.mfaViaEmail=true;
                            }
                            vm.showTotpForm();
                        } else {
                            $uibModalInstance.close();
                        }
                    }
                })
                .catch(_showError)
                .finally(LoaderService.stopLoading);
        }

        function forgotPassword() {
            $window.location = applicationContextRoot + '/dynamic/password-management.jsp';
        }

        function showForgotPassword() {
            var customLoginAlias = localStorage.getItem('SHOW_FORGOT_PASSWORD');
            return customLoginAlias !== 'N';
        }

        function renewSSOSession(){
            var deferred = $q.defer();
            var ssoCookie = $cookies.get('SSOCOOKIE');

            var win = window.open(atob(ssoCookie) + "&RelayState="+ encodeURIComponent("/closeonlogin.jsp"), '_blank');
            var count = 0;
            var intervalId = setInterval(function(){
                count ++;
                if(win.closed ){
                    clearInterval(intervalId);
                    deferred.resolve();
                    vm.closeModal();

                }
                if( count > 100){
                    clearInterval(intervalId);
                    deferred.reject();
                }
            }, 3000);
            return deferred.promise;
        }

        function endSession(){
            dismissModal();
            window.location.href = window.location.origin + '/Alpha/dynamic/login.jsp';
        }

        function validateTotp() {
            LoaderService.startLoading();
            LoginService.validateTotp(vm.login.totpCode, vm.login.trustCookies, vm.login.preferredLanguage, {}, vm.mfaViaEmail)
                .then(function(redirectionUrl) {
                    if (_.endsWith(redirectionUrl, LOGIN_PAGE_PATH)) {
                        vm.showLoginForm();
                    } else {
                        LoginService.getClient()
                            .then(function(clientData) {
                                LoginService.setCookiePolicy(clientData);
                                vm.closeModal();
                            });
                    }
                })
                .catch(_showError)
                .finally(LoaderService.stopLoading);
        }

        function showLoginForm() {
            vm.login.messages = [];
            vm.showTotp = false;
            $timeout(_focusInput);
        }

        function showTotpForm() {
            vm.login.totpCode = null;
            vm.login.messages = [];
            vm.showTotp = true;
        }

        // Private functions

        function _showError(message) {
            vm.login.messages = [{
                text: message,
                messageType: 'Error'
            }];
        }

        function _focusInput() {
            if (vm.login.name) {
                angular.element('#login_modal_password').focus();
            } else {
                angular.element('#login_modal_username').focus();
            }
        }

        function _loadLanguageOptions() {
            LoaderService.startLoading();
            LoginService.getLanguageOptions()
                .then(function(options) {
                    vm.languageOptions = options;
                    vm.login.preferredLanguage = LoginService.getDefaultLanguage(options);
                })
                .catch(_showError)
                .finally(LoaderService.stopLoading);
        }

        function sendCodeViaEmail(){
            vm.sendEmail = I18nUtil.getI18nString('BTN_RESEND_EMAIL', 'Resend Email');
            LoaderService.startLoading();
            LoginService.sendCodeViaEmail()
                .then(function() {
                })
                .catch(_showError)
                .finally(LoaderService.stopLoading);
        }
    }
})();
