(function() {
    'use strict';

    /**
     * An extension for a text input that adds number formatting.
     *
     * @method alphaNumberInput
     *
     * @example
     *      HTML:
     *      <input alpha-number-input
     *          ng-model="someCtrl.modelValue"
     *          decimal-precision="2"
     *          hide-group-separator="true">
     *
     * @param {Number} ng-model Model to bind this widget to.
     * @param {Number} [decimal-precision] Number of decimal places to round values to.
     * @param {Boolean} [hide-group-separator] Whether to hide the group separator when displaying values.
     */

    angular
        .module('alpha.common.directives.alphaNumberInput', [
            'alpha.utils.I18n',
            'alpha.utils.recordData',
            'alpha.utils.RequiredFieldUtils',
            'alpha.common.services.ruleExecutionHelper'
        ])
        .directive('alphaNumberInput', alphaNumberInput);

    alphaNumberInput.$inject = [
        'I18nUtil',
        'RecordDataUtil',
        '$locale',
        'DEFAULT_NUMBER_PRECISION',
        'RequiredFieldService',
        'RuleExecutionHelper'
    ];

    function alphaNumberInput(
        I18nUtil,
        RecordDataUtil,
        $locale,
        DEFAULT_NUMBER_PRECISION,
        RequiredFieldService,
        RuleExecutionHelper
    ) {
        var readOnlyClass = 'readonly';
        var readOnlyRichText = 'alpha-rich-text-read-only';
        return {
            restrict: 'A',
            require: 'ngModel',
            link: link
        };
        function link(scope, element, attrs, ngModelCtrl) {
            var _showGroupSeparator = true,
                _decimalPrecision, _readOnlyMode, _readOnlyState;

            ngModelCtrl.$formatters.push(_formatter);
            ngModelCtrl.$parsers.unshift(_parser);
            ngModelCtrl.$render = _render;

            scope.$watch(I18nUtil.getLocale, _updateLocale);

            attrs.$observe('decimalPrecision', function(newVal) {
                _decimalPrecision = parseInt(newVal);
            });
            attrs.$observe('hideGroupSeparator', function(newVal) {
                _showGroupSeparator = newVal !== 'true';
            });

            RuleExecutionHelper.subscribeToFieldEvents(scope, ['setReadOnly', 'setRequired'], attrs, _eventHandler);
            attrs.$observe('applyRedaction', function(newVal) {
                if (newVal === 'true') {
                    ngModelCtrl.$render();
                }
            });

            attrs.$observe('readOnlyMode', function(newVal) {
                if (newVal === 'true') {
                    _readOnlyMode = true;
                    _enableReadOnly();
                } else {
                    _readOnlyMode = false;
                    if (!_readOnlyState) {
                        _disableReadOnly();
                    }
                }
            });


            function _eventHandler(data, event) {
                switch (event.topic) {
                    case 'setReadOnly':
                        _setReadOnly();
                        if(data.value === true && attrs.readOnly ) {
                            element.addClass(readOnlyRichText)
                        }
                        break;
                    case 'setRequired':
                        if(attrs.eventFieldType) {
                            _setRequired();
                        }
                        break;
                }
                function _setReadOnly() {
                    if (data.value === true) {
                        _readOnlyState = true;
                        _enableReadOnly();
                    } else if (data.value === false) {
                        _readOnlyState = false;
                        if (!_readOnlyMode) {
                            _disableReadOnly();
                        }
                    }
                }
                function _setRequired() {
                    if (data.value === true) {
                        _enableRequired();
                    } else if (data.value === false) {
                            _disableRequired();
                    }
                }
            }




            element.on('keypress', _validateInput);
            element.on('paste', _validatePaste);

            function _formatter(modelValue) {
                // Return modelValue if it contains masked value (e.g., "*****")
                if (typeof modelValue === 'string' && modelValue.includes('*')) {
                    return modelValue;
                }
                return (attrs.applyRedaction === 'true' || _.isNumber(modelValue)) ? _getDisplayValue(modelValue) : '';
            }
            function _parser(viewValue) {
                var numericValue = RecordDataUtil.getNumericValueFromDisplayValue(viewValue),
                    modelValue;
                if (_.isNumber(numericValue)) {
                    modelValue = math.round(numericValue, _.isFinite(_decimalPrecision) ? _decimalPrecision : DEFAULT_NUMBER_PRECISION);
                    ngModelCtrl.$setViewValue(_getDisplayValue(modelValue));
                } else {
                    modelValue = null;
                    ngModelCtrl.$setViewValue('');
                }
                ngModelCtrl.$render();
                return modelValue;
            }
            function _render() {
                element.val(ngModelCtrl.$viewValue);
            }
            function _getModelValue() {
                ngModelCtrl.$modelValue = {}; // force the $formatters pipeline to run
            }
            function _updateLocale() {
                _getModelValue();
            }
            function _getValidCharacters() {
                // There are so many factors that change this that it's better to get it in real time
                var validCharacters = I18nUtil.getDisplayCharacters('Number');
                if (!_showGroupSeparator) {
                    _.pull(validCharacters, $locale.NUMBER_FORMATS.GROUP_SEP);
                }
                if (_decimalPrecision === 0) {
                    _.pull(validCharacters, $locale.NUMBER_FORMATS.DECIMAL_SEP);
                }
                return validCharacters;
            }
            function _stripInvalidCharacters(val) {
                return _.filter(_.split(val, ''), function(char) {
                    return _.includes(_getValidCharacters(), char.toLowerCase());
                }).join('');
            }
            function _getDisplayValue(val) {
                if(attrs.applyRedaction === 'true'){
                    return maskNumber(val);
                }
                return RecordDataUtil.getDisplayValueForField(val, {
                    dataType: 'Number',
                    decimalPrecision: _.isFinite(_decimalPrecision) ? _decimalPrecision : null,
                    decimalPrecisionEnabled: _.isFinite(_decimalPrecision),
                    showGroupSeparator: _showGroupSeparator
                });
            }
            function _validateInput(event){
                if(I18nUtil.isCommaDecimalSeparater() && event.keyCode === 46 && _decimalPrecision !==0){
                    if(document.selection) {
                        var range = document.selection.createRange();
                        range.text = $locale.NUMBER_FORMATS.DECIMAL_SEP; 
                    }else if(this.selectionStart || this.selectionStart == '0') {
                        var start = this.selectionStart;
                        var end = this.selectionEnd;
                        event.target.value = event.target.value.substring(0, start) + $locale.NUMBER_FORMATS.DECIMAL_SEP + event.target.value.substring(end, event.target.value.length);
                        this.selectionStart = start + 1;
                        this.selectionEnd = start +1;
                    } else {
                        event.target.value += $locale.NUMBER_FORMATS.DECIMAL_SEP;
                    }
                    event.preventDefault();
                }
                if (event.key !== 'Enter' && !_.includes(_getValidCharacters(), event.key.toLowerCase())) {
                    event.preventDefault();
                }
            }
            function _validatePaste(event){
                var pastedText;
                if(_.isObject(event.originalEvent.clipboardData)){
                    pastedText = event.originalEvent.clipboardData.getData('text/plain');
                    element.val(_stripInvalidCharacters(pastedText));
                    event.preventDefault();
                }
            }

            function _enableReadOnly() {
                element.addClass(readOnlyClass).prop('readonly', true).prop('disabled', true);
            }
            function _disableReadOnly() {
                element.removeClass(readOnlyClass).prop('readonly', false).prop('disabled', false);
            }
            function _enableRequired() {
                ngModelCtrl.$validators.required = RequiredFieldService.isRequiredFieldValid;
                ngModelCtrl.$setValidity('required', RequiredFieldService.isRequiredFieldValid(ngModelCtrl.$modelValue));
            }
            function _disableRequired() {
                ngModelCtrl.$setValidity('required', true);
                delete ngModelCtrl.$validators.required;
            }

            function maskNumber(val){
                return  val ? '******' : val;
            }
        }
    }
})();
